import React, { useState } from 'react';
import {
    CaretDown,
    DownloadSimple,
    FileText,
} from "@phosphor-icons/react";
import moment from "moment";
import { useDocumentDownload } from '../../../../../../api/admin/useDocumentDownload';

interface RelatedDocument {
    id: number;
    title: string;
    description?: string;
}

interface HistorySectionProps {
    title: string;
    icon: React.ReactNode;
    items: any[];
    type: 'work' | 'education' | 'certificate';
}

const HistorySection = ({ title, icon, items, type }: HistorySectionProps) => {
    const { downloadDocument } = useDocumentDownload();
    const [isExpanded, setIsExpanded] = useState(true);
    const [expandedDocs, setExpandedDocs] = useState<number[]>([]);

    const formatDate = (date: string) => {
        return moment(date).format("MM/DD/YYYY");
    };

    const handleDownloadAll = async (documents: RelatedDocument[], itemId: string) => {
        try {
            const params = {
                ...(type === 'work' && { work_experience_id: itemId }),
                ...(type === 'education' && { education_experience_id: itemId }),
                ...(type === 'certificate' && { certificate_id: itemId })
            };
            
            await downloadDocument(params);
        } catch (error) {
            console.error('Error downloading all documents:', error);
        }
    };

    const handleDownloadDocument = async (document: RelatedDocument) => {
        try {
            await downloadDocument({
                // @ts-ignore
                document_id: document.id
            });
        } catch (error) {
            console.error('Error downloading document:', error);
        }
    };

    const toggleDocDescription = (docIndex: number) => {
        setExpandedDocs(prev =>
            prev.includes(docIndex)
                ? prev.filter(i => i !== docIndex)
                : [...prev, docIndex]
        );
    };

    return (
        <section className="info-section">
            <div className="section-header">
                <div className="header-content">
                    <div className="header-left">
                        {icon}
                        <h2>{title}</h2>
                    </div>
                    <div className="header-line" />
                    <CaretDown
                        size={24}
                        className={`collapse-icon ${isExpanded ? 'expanded' : ''}`}
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                </div>
            </div>

            {isExpanded && (
                <>
                    {type === 'certificate' && (!items || items.length === 0) ? (
                        <div className="no-certificates">No certificate submitted</div>
                    ) : (
                        <div className="history-content">
                            {items.map((item, index) => (
                                <div key={index} className="history-item">
                                    <div className="item-header">
                                        <h3>{title} {index + 1}</h3>
                                        {item.current_job && (
                                            <span className="currently-working">*currently working here</span>
                                        )}
                                    </div>

                                    {type === 'work' ? (
                                        <>
                                            <div className="info-line">
                                                <div className="info-group">
                                                    <label>Company</label>
                                                    <span>{item.company}</span>
                                                </div>
                                                <div className="info-group">
                                                    <label>Job title</label>
                                                    <span>{item.title}</span>
                                                </div>
                                                <div className="info-group">
                                                    <label>Location</label>
                                                    <span>{item.location}</span>
                                                </div>
                                                <div className="info-group">
                                                    <label>Start date</label>
                                                    <span>{formatDate(item.start_date)}</span>
                                                </div>
                                                <div className="info-group">
                                                    <label>End date</label>
                                                    <span>
                                                        {item.current_job ? "Present" : formatDate(item.end_date || "")}
                                                    </span>
                                                </div>
                                            </div>

                                            {item.description && (
                                                <div className="description-section">
                                                    <label>Job Description</label>
                                                    <p>{item.description}</p>
                                                </div>
                                            )}
                                        </>
                                    ) : (type === 'education' ? (
                                        <>
                                            <div className="info-line">
                                                <div className="info-group">
                                                    <label>Institution</label>
                                                    <span>{item.institution_name}</span>
                                                </div>
                                                <div className="info-group">
                                                    <label>Location</label>
                                                    <span>{item.location}</span>
                                                </div>
                                                <div className="info-group">
                                                    <label>Type of degree</label>
                                                    <span>{item.degree_type}</span>
                                                </div>
                                                <div className="info-group">
                                                    <label>Degree program</label>
                                                    <span>{item.program}</span>
                                                </div>
                                            </div>
                                            <div className="info-line">
                                                <div className="info-group">
                                                    <label>Selected degree program</label>
                                                    <span>{item.normalization_choice}</span>
                                                </div>
                                                <div className="info-group">
                                                    <label>Graduation date</label>
                                                    <span>{formatDate(item.end_date || "")}</span>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="info-line">
                                                <div className="info-group">
                                                    <label>Certificate name</label>
                                                    <span>{item.name}</span>
                                                </div>
                                                <div className="info-group">
                                                    <label>Certificate issuer</label>
                                                    <span>{item.certificate_issuer}</span>
                                                </div>
                                                <div className="info-group">
                                                    <label>Issue date</label>
                                                    <span>{item.issue_date}</span>
                                                </div>
                                                <div className="info-group">
                                                    <label>Location</label>
                                                    <span>{item.location}</span>
                                                </div>
                                            </div>
                                            <div className="info-line">
                                                <div className="info-group">
                                                    <label>Certificate URL</label>
                                                    <span>{item.certificate_url}</span>
                                                </div>
                                            </div>
                                            {item.description && (
                                                <div className="description-section">
                                                    <label>Description</label>
                                                    <p>{item.description}</p>
                                                </div>
                                            )}
                                        </>
                                    ))}

                                    <div className="documents-section">
                                        <div className="documents-header">
                                            <h4>Supporting Documents</h4>
                                            {item.related_documents && item.related_documents.length > 0 && (
                                                <button 
                                                    className="download-all"
                                                    onClick={() => handleDownloadAll(item.related_documents, item.id)}
                                                >
                                                    <DownloadSimple size={20} />
                                                    <span>Download all</span>
                                                </button>
                                            )}
                                        </div>
                                        <div className="documents-list">
                                            {item.related_documents && item.related_documents.length > 0 ? (
                                                item.related_documents.map((doc: RelatedDocument, docIndex: number) => (
                                                    <div key={docIndex} className="document-item">
                                                        <div className="document-info">
                                                            <FileText size={20} />
                                                            <div className="document-text">
                                                                <span className="document-title">{doc.title}</span>
                                                                {doc.description && expandedDocs.includes(docIndex) && (
                                                                    <p className="document-description">{doc.description}</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="document-actions">
                                                            <button 
                                                                className="download-button"
                                                                onClick={() => handleDownloadDocument(doc)}
                                                            >
                                                                Download
                                                            </button>
                                                            {doc.description && (
                                                                <CaretDown
                                                                    size={20}
                                                                    className={`toggle-description ${expandedDocs.includes(docIndex) ? 'expanded' : ''}`}
                                                                    onClick={() => toggleDocDescription(docIndex)}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="no-documents">No documents attached.</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </section>
    );
};

export default HistorySection;