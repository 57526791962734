import './profile-file-upload.scss';
import LabelKMQ from "../../../../UI/Label/LabelKMQ";
import UploadIcon from "../../../../../assets/icons/UI/icons/upload.svg";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {useSubmitDocument} from "../../../../../api/cpl-applicant/useMutateSubmitDocument";
import FileInfo from "./FileInfo";
import {deepClone} from "../../../../../lib/resume-parser/deep-clone";
import {toast} from "react-toastify";

export interface IAttachment {
  file: File,
  name: string,
  description: string,
  saved: boolean,
  edit: boolean,
  id?: number
}

const FileUpload = ({onSave, files}: { files: IAttachment[], onSave: (el: any) => void }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  //const [files, setFiles] = useState<IAttachment[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const validFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  const {mutateAsync: submitDocument, isPending: submitDocumentPending} = useSubmitDocument();

  const setFiles = (filesFist: IAttachment[]) => {
    onSave({related_documents: filesFist});
  }

  const handleAddFile = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && validFileTypes.includes(selectedFile.type)) {
      setFiles([...files, {
        file: selectedFile,
        name: '',
        description: '',
        saved: false,
        edit: false
      }]);
    }
  }

  const editFile = (newFile: IAttachment) => {
    setFiles(files.map((item) => item.file.name === newFile.file.name ? newFile : item));
  }

  const removeFile = (removeFile: IAttachment) => {
    setFiles(files.filter((item) => item.file.name !== removeFile.file.name));
  }

  const saveFile = async (newFile: IAttachment) => {
    if (!newFile.description || !newFile.name) {
      toast.error('Please fill all the fields.');
      return;
    }
    try {
      const response = await submitDocument({
        title: newFile.name,
        description: newFile.description,
        file: newFile.file,
      });
      //onSave({related_documents: files.filter(item => item.saved).map(item => item.id)})
      setFiles(files.map((item) => item.file.name === newFile.file.name
        ? {...newFile, saved: true, edit: false, id: response.id}
        : item
      ));
    } catch (error) {
      console.error('Error submitting document:', error);
    }
  };

  const handleInputClick = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  }

  /*useEffect(() => {
    if (files.length > 0) {
      onSave({related_documents: files})
    }
  }, [files])

  useEffect(() => {

  }, [onSave])*/

  return <div>
    <div className={'profile-file-upload'}>
      <div className={'profile-file-upload-title'}>
        Supporting Documents
        <span className={'profile-file-upload-title-optional'}>
        {' (Optional)'}
      </span>
      </div>
      <LabelKMQ>
        Upload Attachments
      </LabelKMQ>

      <div className={'profile-file-upload-container'}>
        <div className={'profile-file-upload-container-image'}
             onClick={handleInputClick}
        >
          <img
            src={UploadIcon}
            alt={'upload'}
          />
          <input
            type="file"
            onChange={handleAddFile}
            ref={fileInputRef}
            accept=".doc,.docx,.pdf"
          />
        </div>
        <div className={'profile-file-upload-container-description'}>
          <div className={'profile-file-upload-container-description-title'}>
            Drag your files here
          </div>
          <div className={'profile-file-upload-container-description-text'}>
            Accepted file formats: .docx, .doc, .pdf, .jpg, .png, .mp4, .mov
            <br/>
            Max files size: 50MBs | Max files: 5
          </div>
        </div>
        <div className={'profile-file-upload-container-divider'}>
          <div className={'profile-file-upload-container-divider-line'}/>
          <div className={'profile-file-upload-container-divider-text'}>
            OR
          </div>
          <div className={'profile-file-upload-container-divider-line'}/>
        </div>
        <div className={'profile-file-upload-container-browse'}>
        <span className={'profile-file-upload-container-browse-link'} onClick={handleInputClick}>
          Browse
        </span>
          {' your computer'}
        </div>
      </div>
    </div>

    {files.map((item, index) =>
      <div key={index}>
        <FileInfo
          file={item}
          editFile={editFile}
          onSave={() => saveFile(item)}
          onCancel={() => removeFile(item)}
          isLoading={submitDocumentPending}
        />
      </div>)}
  </div>
}

export default FileUpload;