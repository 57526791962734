import React, {useContext, useMemo} from 'react';
import './progress-bar.scss';
import {Check} from "@phosphor-icons/react";
import {UserContext} from "../../../../hooks/Context/UserContext";

interface ProgressBarProps {
  currentPage: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({currentPage}) => {
  const [user] = useContext(UserContext);
  const steps = useMemo<string[]>(() => {
    if (user.isSoft) {
      return ['Resume', 'Profile', 'Work History', 'Education History', 'Certifications', 'Review', 'Submit'];
    }
    return ['Resume', 'Work History', 'Education History', 'Certifications', 'Review', 'Submit']
  }, [user]);
  const currentIndex = steps.indexOf(currentPage);

  return (
    <div className="resume-progress-bar-container">
      {steps.map((step, index) => (
        <div className={"resume-progress-bar-label-connector-container"}>
          <React.Fragment key={step}>
            <div className={"resume-progress-bar-connector"}>
              <div className={`step ${index <= currentIndex ? 'active' : ''}`}>
                {index < currentIndex ? (
                  <Check size={20} color="white" className="check-icon"/>
                ) : index === currentIndex ? (
                  <span className="round-dot"></span>
                ) : <div className="current-dot"></div>}
              </div>
              {index < steps.length - 1 && <div className="connector"></div>}
            </div>
            <div className="label">
              {
                window.screen.width <= 400 && step === 'Work Experience'
                  ? 'OCCUPATIONS'
                  : step.toUpperCase()
              }
            </div>
          </React.Fragment>
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
