import './evidences.scss';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import {useQueryPrograms} from "../../../api/admin/useQueryPrograms";
import {useQueryEvidences} from "../../../api/admin/useQueryEvidences";
import SkillChiclet from "../../../components/UI/Chiclets/SkillChiclet/SkillChiclet";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import {ButtonTypes} from "../../../constants/ui/types/ButtonTypes";
import Evidence from "../../../components/Evidence/Evidence";
import Loading from "../../../components/UI/Loading/Loading";
import {useMutateCourseFeedback} from "../../../api/admin/useMutateCourseFeedback";
import AlertModal from "../../../components/UI/Modals/AlertModal/AlertModal";
import ProgressChiclet from "../../../components/UI/Chiclets/ProgressChiclet/ProgressChiclet";
import {ChicletTypes} from "../../../constants/ui/types/ChicletTypes";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import {PermissionType} from "../../../constants/types/PermissionType";
import {UserContext} from "../../../hooks/Context/UserContext";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import {usePostQuiz} from "../../../api/quiz/postQuiz";
import {useGetQuizRequest} from "../../../api/quiz/useGetQuizRequest";
import SmartLoading from "../../../components/UI/Loading/SmartLoading";
import PageNotFound from '../../login/404-page/PageNotFound';
import NotificationRequired from "../../../components/UI/Notification/NotificationRequired";
import EvidenceButton from "../../../components/UI/Button/EvidenceButton";
import DocumentIcon from '../../../assets/icons/UI/icons/document.svg';
import QuizIcon from '../../../assets/icons/UI/icons/quiz.svg';

const EvidencesPage = () => {
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const {portfolio_id, program_id, course_code} = useParams();
  const [portfolio] = useContext(PortfolioContext);
  const [openedTables, setOpenedTables] = useState<number[]>([]);
  const [creatingQuiz, setCreatingQuiz] = useState<boolean>(false);
  const [approveCourseModal, setApproveCourseModal] = useState<boolean>(false);
  const [rejectCourseModal, setRejectCourseModal] = useState<boolean>(false);
  const [learningOutcomesQuiz, setLearningOutcomesQuiz] = useState<number[]>([]);
  const {
    mutate: postQuiz,
    data: newQuizData,
    isSuccess: quizSuccess,
    isPending: quizLoading
  } = usePostQuiz();

  const {data: currProgram} = useQueryPrograms({
    portfolio_id: portfolio_id,
    program_id: program_id,
  });

  const {data: evidences} = useQueryEvidences({
    portfolio_id: portfolio_id,
    course_code: course_code
  });

  const {data: quizData} = useGetQuizRequest({
    course_id: evidences?.course_id,
    portfolio_id: portfolio_id
  })

  const {
    mutate: postFeedback,
  } = useMutateCourseFeedback();

  const toggleOpenedTable = (index: number) => {
    if (openedTables.includes(index)) {
      setOpenedTables(openedTables.filter(item => item !== index))
    } else {
      setOpenedTables([...openedTables, index])
    }
  }

  const handleApproveCourse = () => {
    postFeedback({
      course_recommendation_id: evidences.course_recommendation_id,
      approved: true,
      feedback_list: []
    });
    setApproveCourseModal(false);
  }

  const handleRejectCourse = () => {
    navigate(location.pathname + '/reject-reason');
    setRejectCourseModal(false);
  }

  const handlePostQuiz = () => {
    postQuiz({
      course_id: evidences.course_id,
      learning_outcomes: learningOutcomesQuiz.map(item => {
        return {
          learning_outcome_id: item,
          num_questions: 1 // we only allow 1 question per learning outcome. may change it in future
        }
      })
    });
  }

  const handleRequestQuiz = () => {
    if (quizData?.status === 'completed') {
      navigate(location.pathname + '/quiz/' + quizData?.course_quiz?.assessment_id + '/results')
    } else {
      setCreatingQuiz(!creatingQuiz);
    }
  }

  const handleAddAllToQuiz = () => {
    if (evidences.learning_outcomes.length === learningOutcomesQuiz.length) {
      setLearningOutcomesQuiz([]);
    } else {
      setLearningOutcomesQuiz(evidences.learning_outcomes.map((item: any) => item.id));
    }
  }

  const handleAddToQuiz = (idSelected: number) => {
    let filteredIds = learningOutcomesQuiz.filter(item => item !== idSelected);
    if (filteredIds.length !== learningOutcomesQuiz.length) {
      setLearningOutcomesQuiz(filteredIds);
    } else {
      setLearningOutcomesQuiz([...learningOutcomesQuiz, idSelected]);
    }
  }

  if (quizSuccess) {
    navigate(location.pathname + '/quiz/' + newQuizData?.assessment_id)
  }

  if (portfolio?.status === "Pending payment") {
    return <PageNotFound header_logo_required={false}/>;
  }

  if (!currProgram || !evidences) {
    return <Loading loading/>
  }

  let documentUploadDisabled = !portfolio?.assigned_to_me && !user.permission_groups?.includes(PermissionType.cpl_super_admin);
  let quizDisabled = (!portfolio?.assigned_to_me && !user.permission_groups?.includes(PermissionType.cpl_super_admin))
    || (quizData && quizData?.status !== 'completed');

  return <div>
    <SmartLoading
      loading={quizLoading}
      text={'Generating questions'}
    />
    {
      !evidences.active_catalogue_version
        ? <NotificationRequired>
          Attention required: Criteria for this course has recently been updated and may require further review before
          processing.
        </NotificationRequired>
        : null
    }

    <div className={'evidences'}>
      <Breadcrumbs content={[
        {
          label: 'Applicants',
          link: `/cpl-admin`
        },
        {
          label: `${portfolio.portfolio_user_name}’s profile`,
          link: `/cpl-admin/${portfolio_id}`
        },
        {
          label: `${currProgram?.name}`,
          link: `/cpl-admin/${portfolio_id}/${program_id}`
        },
        {
          label: `${evidences?.title}`,
          link: `/cpl-admin/${portfolio_id}/${program_id}/${course_code}`
        }
      ]}/>
      <div className={'evidences-chiclets'}>
        <SkillChiclet
          text={`${Math.floor(evidences.matching_score * 100)}% match`}
        />
        {
          evidences.approved
            ? <ProgressChiclet text={'Approved'} type={ChicletTypes.Completed}/>
            : null
        }
        {
          evidences.approved === false
            ? <ProgressChiclet text={'Rejected'} type={ChicletTypes.Overdue}/>
            : null
        }
      </div>
      <div className={'evidences-title'}>
        {evidences.title}
      </div>
      <div className={'evidences-code'}>
        {(course_code ?? '').toUpperCase()}
      </div>
      <div className={'evidences-description'}>
        <div className={'evidences-description-text'}>
          {evidences.description}
        </div>
        <div className={'evidences-description-buttons'}>
          <EvidenceButton
            text={'Approve credit'}
            onClick={() => setApproveCourseModal(true)}
            disabled={!portfolio.assigned_to_me || evidences.approved !== null}
            type={'approve'}
          />
          <EvidenceButton
            text={'Reject credit'}
            onClick={() => setRejectCourseModal(true)}
            disabled={!portfolio.assigned_to_me || evidences.approved !== null}
            type={'reject'}
          />
        </div>
      </div>

      <div className={'evidences-buttons'}>
        <span className={`evidences-buttons-option 
            ${documentUploadDisabled ? 'evidences-buttons-option-disabled' : ''}`}
              onClick={documentUploadDisabled ? undefined : () => navigate(`/cpl-admin/${portfolio_id}/request-document-upload?course_code=${course_code}`)}
        >
          <img
            src={DocumentIcon}
            alt={'document'}
            className={'evidences-buttons-option-image'}
          />
          <span className={'evidences-buttons-option-text'}>
            Request Documents
          </span>
        </span>
        <span className={`evidences-buttons-option 
          ${quizDisabled ? 'evidences-buttons-option-disabled' : ''}`}
              onClick={quizDisabled ? undefined : handleRequestQuiz}
        >
          <img
            src={QuizIcon}
            alt={'quiz'}
            className={'evidences-buttons-option-image'}
          />
          <span className={'evidences-buttons-option-text'}>
            {quizData ? 'View quiz result' : 'Request quiz'}
          </span>
        </span>
      </div>

      <div className={'evidences-table-title'}>
        {creatingQuiz ? 'Create Quiz' : 'Evidence Table'}
      </div>
      {
        creatingQuiz
          ? <span className={'evidences-table-select'}>
            <Checkbox
              active={evidences.learning_outcomes.length === learningOutcomesQuiz.length}
              handleClick={handleAddAllToQuiz}
              text={'Select all'}
              disabled={!!quizData}
            />
          </span>
          : null
      }

      {
        evidences && evidences.learning_outcomes.map((item: any, index: number) =>
          <Evidence
            index={index + 1}
            title={item.description}
            isOpened={openedTables.includes(index)}
            toggleOpened={() => toggleOpenedTable(index)}
            evidences={item.evidence_sentence_pairs}
            canSendFeedback={portfolio.assigned_to_me}
            addToQuiz={() => handleAddToQuiz(item.id)}
            active={!!learningOutcomesQuiz.find(el => el === item.id)}
            showCheckbox={creatingQuiz}
          />
        )
      }

      {
        creatingQuiz
          ? <div className={'evidences-table-footer'}>
            <ButtonKMQ onClick={() => setCreatingQuiz(false)} type={ButtonTypes.Secondary}>
              Cancel
            </ButtonKMQ>
            <ButtonKMQ onClick={handlePostQuiz} type={ButtonTypes.Tertiary}>
              Create Quiz
            </ButtonKMQ>
          </div>
          : null
      }
      <AlertModal
        open={approveCourseModal}
        onClose={() => setApproveCourseModal(false)}
        title={'Approve course recommendation?'}
        text={`Do you wish to approve the course '${evidences.title}' for ${portfolio?.portfolio_user_name}?`}
        onProceed={handleApproveCourse}
        proceedText={'Approve credit'}
        closeText={'Cancel'}
      />

      <AlertModal
        open={rejectCourseModal}
        onClose={() => setRejectCourseModal(false)}
        title={'Reject course recommendation?'}
        text={`Do you wish to reject the course '${evidences.title}' for ${portfolio?.portfolio_user_name}?`}
        onProceed={handleRejectCourse}
        proceedText={'Reject credit'}
        closeText={'Cancel'}
      />
    </div>
  </div>
}

export default EvidencesPage;