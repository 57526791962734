import './cpl-applicant-profile.scss';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { ResumeContext } from '../../../hooks/Context/ResumeContext';
import { PortfolioContext } from '../../../hooks/Context/PortfolioContext';
import { portfolioToResume } from '../../../lib/data-formatting/portfolioToResume';
import { resumeToProfile } from '../../../lib/data-formatting/resumeToProfile';
import { useMutateNormalization } from '../../../api/normalization/useQueryNormalization';
import WorkExperienceSection from './WorkExperienceSection';
import EducationSection from './EducationSection';
import {CurrOptionsType, NormalizationOption, SectionType} from '../../../lib/resume-parser/redux/types';
import { EDUCATION_STATE, REVIEW_STATE, WORK_EXPERIENCE_STATE, CERTIFICATES_STATE } from '../../../constants/resume/default';
import {deepClone} from "../../../lib/resume-parser/deep-clone";
import CertificatesSection from './CertificationSection';

interface ParsingResumeComponentProps {
  eduRef?: React.RefObject<HTMLDivElement>;
  workRef?: React.RefObject<HTMLDivElement>;
  certRef?: React.RefObject<HTMLDivElement>;
  handleAddWork: () => void;
  handleAddEducation: () => void;
  handleAddCertificates: () => void;
  isRecommendationError: boolean;
  type: SectionType;
  index?: number;
  setCurrentWorkIndex?: (index: number) => void;
  setCurrentEduIndex?: (index: number) => void;
  setCurrentCertIndex?: (index: number) => void;
  portfolio_submitted?: boolean;
}

const CplApplicantProfile = forwardRef(({
  eduRef,
  workRef,
  certRef,
  handleAddWork,
  handleAddEducation,
  handleAddCertificates,
  isRecommendationError,
  type,
  index = 0,
  setCurrentWorkIndex,
  setCurrentEduIndex,
  setCurrentCertIndex,
  portfolio_submitted,
}: ParsingResumeComponentProps, ref) => {
    const [portfolio] = useContext(PortfolioContext);
  const [parsedResume, setParsedResume] = useContext(ResumeContext);
  const [currOptions, setCurrOptions] = useState<CurrOptionsType>({ jobs: [], educations: [] });
  const { mutate: postNormalization } = useMutateNormalization(setCurrOptions);

  useEffect(() => {
    if (parsedResume.resume_storage_name) postNormalization(resumeToProfile(parsedResume));
  }, [parsedResume.resume_storage_name]);

  useEffect(() => {
    if (currOptions) {
      const newResume = deepClone(parsedResume);
      currOptions.educations.forEach((options: NormalizationOption, index: number) => {
        if (options.normalization_choices?.find(item => item.title === options.title)) {
          newResume.education[index].normalized = options.title
        }
      })
      setParsedResume(newResume);
    }
  }, [currOptions])

  useEffect(() => {
    if (portfolio.id && portfolio.portfolio_email) {
      const updatedPortfolio = portfolioToResume(portfolio);

      setParsedResume({
        ...updatedPortfolio,
        // @ts-ignore
        start_over: portfolio.start_over ? portfolio.start_over : undefined,
      });
    }
  }, [portfolio]);
  

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const renderSection = () => {
    switch (type) {
      case WORK_EXPERIENCE_STATE:
        return (
          <WorkExperienceSection
            index={index}
            currOptions={currOptions}
            setCurrOptions={setCurrOptions}
            setCurrentWorkIndex={setCurrentWorkIndex}
            isRecommendationError={isRecommendationError}
            handleAddWork={handleAddWork}
          />
        );
      case EDUCATION_STATE:
        return (
          <EducationSection
            index={index}
            currOptions={currOptions}
            setCurrOptions={setCurrOptions}
            setCurrentEduIndex={setCurrentEduIndex}
            isRecommendationError={isRecommendationError}
            handleAddEducation={handleAddEducation}
          />
        );
      case CERTIFICATES_STATE:
        return (
          <CertificatesSection
            index={index}
            setCurrentCertIndex={setCurrentCertIndex}
            handleAddCertificates={handleAddCertificates}
          />
        );
      case REVIEW_STATE:
        return (
        <>
          <div className="edit-resume-education-title">Review</div>
          <div>
            <WorkExperienceSection
              workRef={workRef}
              currOptions={currOptions}
              setCurrOptions={setCurrOptions}
              isRecommendationError={isRecommendationError}
              isReview
              portfolioSubmitted={portfolio_submitted}
              handleAddWork={handleAddWork}
            />
            <EducationSection
              eduRef={eduRef}
              currOptions={currOptions}
              setCurrOptions={setCurrOptions}
              isRecommendationError={isRecommendationError}
              isReview
              portfolioSubmitted={portfolio_submitted}
              handleAddEducation={handleAddEducation}
            />
            <CertificatesSection
            certRef={certRef}
            isReview
            portfolioSubmitted={portfolio_submitted}
            handleAddCertificates={handleAddCertificates}
          />
          </div>
          </>
        );
      default:
        return <div>Invalid section type</div>;
    }
  };

  return <div>{renderSection()}</div>;
});

export default CplApplicantProfile;
